<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ `${type === 'create' ? '新增' : type === 'edit' ? '编辑' : '查看'}新房` }}</h3>
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption"
               @submit="handleSubmit"
               :upload-error="uploadError"
               :upload-delete="uploadDelete"
               :upload-after="uploadAfter"
               :upload-before="uploadBefore">

      <template slot="cityId">
        <el-select :disabled="disabled" v-model="form.cityId" clearable @change="handleCityId">
          <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="countyId">
        <el-select :disabled="disabled" v-model="form.countyId" clearable @change="handleCountyId">
          <el-option v-for="(item,index) in countyList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="businessId">
        <el-select :disabled="(!form.countyId && !disabled) || disabled" v-model="form.businessId" clearable @change="handleBusinessId">
          <el-option v-for="(item,index) in businessList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="buildId">
        <el-select :disabled="(!form.businessId && !disabled) || disabled" filterable v-model="form.buildId" clearable>
          <el-option v-for="(item,index) in buildIdList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="averagePrice">
        <el-input v-model="form.averagePrice"
                  placeholder="请输入 参考均价"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'averagePrice',999999.99,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">元/m²</template>
        </el-input>
      </template>
      <template slot="propertyRight">
        <el-input v-model="form.propertyRight"
                  placeholder="请输入 产权"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'propertyRight',70,'integer')"
                  onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')">
          <template slot="append">年</template>
        </el-input>
      </template>
      <template slot="rebateScale">
        <el-input v-model="form.rebateScale"
                  placeholder="请输入 服务费-返佣比例"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'rebateScale',100,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">%</template>
        </el-input>
      </template>
      <template slot="payerScale">
        <el-input v-model="form.payerScale"
                  placeholder="请输入 服务费-支付方分账比例"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'payerScale',100,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">%</template>
        </el-input>
      </template>
      <template slot="platformScale">
        <el-input v-model="form.platformScale"
                  placeholder="请输入 服务费-平台分账比例"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'platformScale',100,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">%</template>
        </el-input>
      </template>
      <template slot="introsBtn">
        <div v-if="disabled"></div>
        <el-button type="primary" v-if="!disabled" @click="introsCreate">新增</el-button>
      </template>

      <template slot="intros">
        <avue-crud
            :data="form.intros"
            :option="option"
        >
          <template slot="menu" slot-scope="{ row }">
            <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row,row.$index)">编辑</el-button>
            <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row.$index)">删除</el-button>
          </template>
        </avue-crud>
      </template>
      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleBack">取消</el-button>
      </template>
    </avue-form>
    <CreateForm v-if="show" :show="show" :createIndex="createIndex" :createType="createType" :createInfo="createInfo" @close="close" @createSubmit="createSubmit"/>
  </div>
</template>

<script>
import {listBuild, listBusiness, listCity, listCounty} from "@/api/house/second";
import {add, update, view, listLabel} from "@/api/house/new"
import CreateForm from "./createForm";
export default {
  name: "createOne",
  components: {
    CreateForm
  },
  data(){
    return{
      id: this.$route.query.id,
      type: this.$route.query.type,
      form: {
        title: '',
        cityId: '',
        countyId: '',
        businessId: '',
        buildId: '',
        averagePrice: '',
        openTime: '',
        propertyRight: '',
        labelIds: [],
        rebateScale: '',
        payerScale: '',
        platformScale: '',

        introsBtn: 'introsBtn',
        intros: []
      },
      loading: false,
      disabled: this.$route.query.type === 'view',
      formOption: {
        submitText: '确定',
        submitBtn: this.$route.query.type !== 'view',
        emptyBtn: false,
        labelWidth: 200,
        disabled: this.$route.query.type === 'view',
        column: [
          {
            label: '房源标题',
            prop: 'title',
            maxlength: 30,
            showWordLimit: true,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择房源标题",
              trigger: "blur"
            }]
          },
          {
            label: '城市',
            prop: 'cityId',
            type: 'select',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            span: 8,
            rules: [{
              required: true,
              message: "请选择城市",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择城市'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }]
          },
          {
            label: '',
            prop: 'countyId',
            type: 'select',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            labelWidth: 0,
            span: 5,
            placeholder: '请选择 区域',
            rules: [{
              required: true,
              message: "请选择区域",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择区域'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }]
          },
          {
            label: '商圈',
            prop: 'businessId',
            type: "select",
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择商圈",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择商圈'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }],
            disabled: true
          },
          {
            label: '楼盘名称',
            prop: 'buildId',
            type: "select",
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            filterable: true,
            span: 13,
            row: true,
            placeholder: '选择所选市、区/县的小区名称',
            rules: [{
              required: true,
              message: "请选择楼盘",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择楼盘'));
                }else{
                  callback();
                }
              }
            }],
            disabled: true
          },
          {
            label: '参考均价',
            prop: 'averagePrice',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入参考均价",
              trigger: "blur"
            }]
          },
          {
            label: '开盘时间',
            prop: 'openTime',
            type: 'date',
            span: 13,
            row: true,
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            rules: [{
              required: true,
              message: "请选择开盘时间",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择开盘时间'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '产权',
            prop: 'propertyRight',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入产权",
              trigger: "blur"
            }]
          },
          {
            label: '标签',
            prop: 'labelIds',
            type: 'select',
            // 多选
            multiple: true,
            props: {
              label: 'name',
              value: 'id'
            },
            dicData: [],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择标签",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value.length === 0){
                  callback(new Error('请选择标签'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '服务费-返佣比例',
            prop: 'rebateScale',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入服务费-返佣比例",
              trigger: "blur"
            }],
            display: this.$route.query.type !== 'view'
          },
          {
            label: '服务费-支付方分账比例',
            prop: 'payerScale',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入服务费-支付方分账比例",
              trigger: "blur"
            }],
            display: this.$route.query.type !== 'view'
          },
          {
            label: '服务费-平台分账比例',
            prop: 'platformScale',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入服务费-平台分账比例",
              trigger: "blur"
            }],
            display: this.$route.query.type !== 'view'
          },
          {
            label: '房源图片上传',
            prop: 'images',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传9张，建议尺寸：750*560',
            fileSize: 20 * 1024,
            span: 24,
            limit: 9,
            rules: [{
              required: true,
              message: "请上传房源图片",
              trigger: "blur"
            }]
          },
          {
            label: '户型介绍',
            prop: 'introsBtn',
            span: 13,
            row: true,
            rules: [{required: true}]
          },
          {
            label: '',
            prop: 'intros',
            span: 24,
            row: true,
            rules: [{
              required: true,
              message: '请添加户型',
              trigger: 'blur'
            }]
          }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      buildName: '',
      uploadList: [], //上传图片数据列表

      option: {
        title: '',
        titleSize: 'h3',
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号', // 索引文本
        indexWidth: 100,  // 索引宽度
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        menuWidth: 300,
        menu: this.$route.query.type !== 'view',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '户型图片',
            prop: 'image',
            type: 'img'
          },
          {
            label: '户型',
            prop: 'bedroom',
            formatter: (row, column, cellValue) => {
              // console.log(row, column, cellValue)
              return `${cellValue}室${row.office}厅${row.toilet}卫`;
            }
          },
          {
            label: '建面(m²)',
            prop: 'floorage'
          },
          {
            label: '期望售价(万元)',
            prop: 'sellPrice'
          }
        ]
      },

      show: false,
      createType: 'create',
      createIndex: 0,
      createInfo: {},

      cityList: [],
      countyList: [],
      businessList: [],
      buildIdList: []
    }
  },
  async created() {
    this.uploadList = this.formOption.column.filter(item => item.type === 'upload'); //获取上传组件
    await this.getListLabel();
    await this.getListCity();
  },
  async mounted() {
    if(this.$route.query.id){
      this.loading = true;
      await this.getView();
    }
  },
  methods: {
    async getView(){
      const res = await view({
        id: this.$route.query.id
      })
      // console.log(res.data)
      if(res.code === 200){
        this.form = {
          ...res.data,
          introsBtn: 'introsBtn'
        }
        await this.getListCity();
        await this.getListCounty(res.data.cityId);
        await this.getListBusiness(res.data.countyId);
        await this.getListBuild()
      }else{
        this.$message.error(res.msg)
      }
    },
    handleSubmit(form,done){
      console.log(form)
      if(this.id){
        update({
          id: this.id,
          ...form
        }).then(res => {
          if(res.code === 200){
            this.$message.success('修改成功');
            this.$router.go(-1)
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }else{
        add(form).then(res=>{
          if(res.code === 200){
            this.$message.success('添加成功');
            this.$router.go(-1)
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }
    },
    handleBack(){
      this.$router.go(-1)
    },
    async getListCity(){
      const { data } = await listCity();
      // console.log(data);
      this.cityList = data || [];
    },
    async handleCityId(e){
      this.form.countyId = '';
      this.form.businessId = '';
      this.form.buildId = '';
      await this.getListCounty(e)
    },
    // listCounty
    async getListCounty(id){
      if(id){
        const { data } = await listCounty({
          id: id
        });
        this.countyList = data || [];
      }else{
        this.countyList = [];
        this.form.countyId = '';
      }
    },
    async handleCountyId(val){
      this.form.businessId = '';
      this.form.buildId = '';
      await this.getListBusiness(val)
    },
    async getListBusiness(id){
      if(id){
        const { data } = await listBusiness({
          id: id
        });
        // console.log(data)
        this.businessList = data || [];
      }else{
        this.businessList = [];
        this.form.businessId = '';
      }
    },
    async handleBusinessId(e){
      this.form.buildId = '';
      e && await this.getListBuild()
    },
    async getListLabel(){
      const { data } = await listLabel()
      // console.log(data)
      const labelIds = this.findObject(this.formOption.column, 'labelIds');
      labelIds.dicData = data || [];
    },
    // 选择户型---室
    changeBedroom(val){
      console.log('选择户型---室',val)
      if(val && this.form.office && this.form.toilet){
        this.form.bedroomOffice = val + '室' + this.form.office + '厅' + this.form.toilet + '卫';
      }else{
        this.form.bedroomOffice = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 选择户型---厅
    changeOffice(val){
      console.log('选择户型---厅',val)
      if(val && this.form.bedroom && this.form.toilet){
        this.form.bedroomOffice = this.form.bedroom + '室' + val + '厅' + this.form.toilet + '卫';
      }else{
        this.form.bedroomOffice = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 选择户型---卫
    changeToilet(val){
      console.log('选择户型---卫',val)
      if(val && this.form.bedroom && this.form.office){
        this.form.bedroomOffice = this.form.bedroom + '室' + this.form.office + '厅' + val + '卫'
      }else{
        this.form.bedroomOffice = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 付款方式---押
    changePledge(val){
      console.log('付款方式---押',val)
      if(val && this.form.pay){
        this.form.pledgePay = '押' + val + '付' + this.form.pay;
      }else{
        this.form.pledgePay = '';
      }
      // console.log(this.form.pledgePay)
    },
    // 付款方式---付
    changePay(val){
      console.log('付款方式---付',val)
      if(val && this.form.pledge){
        this.form.pledgePay = '押' + this.form.pledge + '付' + val;
      }else{
        this.form.pledgePay = '';
      }
      // console.log(this.form.pledgePay)
    },
    // 限制输入框
    changeBlur(e,text,max,type){
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          if(Number(value) > Number(max)){
            if (type === 'integer') {
              this.form[text] = Number(max)
            }else{
              this.form[text] = Number(max).toFixed(2)
            }
          }else{
            if (type === 'integer') {
              this.form[text] = Number(value)
            } else {
              this.form[text] = Number(value).toFixed(2)
            }
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
    async getListBuild(){
      const { data } = await listBuild({
        cityId: this.form.cityId,
        countyId: this.form.countyId,
        businessId: this.form.businessId,
        name: this.buildName
      })
      // console.log(data)
      this.buildIdList = data || [];
      // 数据加载完成
      console.log('数据加载完成')
      this.loading = false;
    },
    handleIsUnit(val){
      const unitNumber = this.findObject(this.formOption.column, 'unitNumber');
      if(val === 2){
        unitNumber.rules.some(item => {
          if(item.required){
            item.required = false;
            return true;
          }
        })
      }else{
        unitNumber.rules.some(item => {
          if(!item.required){
            item.required = true;
            return true;
          }
        })
      }
    },
    handleIsHouse(val){
      const houseNumber = this.findObject(this.formOption.column, 'houseNumber');
      if(val === 2){
        houseNumber.rules.some(item => {
          if(item.required){
            item.required = false;
            return true;
          }
        })
      }else{
        houseNumber.rules.some(item => {
          if(!item.required){
            item.required = true;
            return true;
          }
        })
      }
    },
    introsCreate(){
      // console.log('introsCreate')
      this.show = true;
      this.createType = 'create';
    },
    handleEdit(row,index){
      console.log('handleEdit',row,index)
      this.show = true;
      this.createType = 'edit';
      this.createInfo = row;
      this.createIndex = index;
    },
    handleDelete(index){
      this.$confirm('是否删除此户型信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.intros.splice(index,1)
        const intros = this.findObject(this.formOption.column, 'intros');
        if(this.form.intros.length === 0){
          intros.rules.some(item => {
            if(!item.required){
              item.required = true;
              return true;
            }
          })
        }
      })
    },
    createSubmit(e){
      console.log(e)
      if(e.type === 'create'){
        this.form.intros.push(e.form)
      }else{
        this.form.intros.splice(e.index,1,e.form)
      }
      console.log(this.form.intros)
      this.show = false;
      const intros = this.findObject(this.formOption.column, 'intros');
      if(this.form.intros.length > 0){
        intros.rules.some(item => {
          if(item.required){
            item.required = false;
            return true;
          }
        })
      }
    },
    close(){
      this.show = false;
    },

    // 图片上传绑定事件

    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          if(action === 'confirm'){
            let array = JSON.parse(JSON.stringify(this.form[column.prop].split(',')));
            let index = array.findIndex(item=>item === file.url)
            array.splice(index,1);
            column.propsHttp.name = array.toString();
            if(array.length < column.limit){
              this.upload('inline-block',column.prop);
            }
          }
          done();
        }
      });
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        if(column.propsHttp.name){
          column.propsHttp.name += ',' + res.path
        }else{
          column.propsHttp.name = res.path;
        }
        if(column.propsHttp.name.split(',').length >= column.limit) {
          this.upload('none',column.prop);
        }
      }
    },
    upload(type,prop){
      let index = this.uploadList.findIndex(item => item.prop === prop)
      // console.log(index)
      let upload = document.getElementsByClassName('el-upload')[index];
      upload.style.display = type === 'none' ? 'none' : 'inline-block';
    },
  }
}
</script>

<style scoped>
.width_50{
  width: 50%;
}
.width_60{
  width: 60%;
}
/deep/ .el-checkbox .el-checkbox__label{
  font-size: 12px;
  line-height: 13px;
}
</style>